import dynamic from "next/dynamic"

export const templateMapping = {
  archive: dynamic(() => import("@/templates/archive/archive")),
  article: dynamic(() => import("@/templates/article/article")),
  campaign: dynamic(() => import("@/templates/campaign/campaign")),
  custom: dynamic(() => import("@/templates/custom/custom")),
  error: dynamic(() => import("@/templates/error/error")),
  event: dynamic(() => import("@/templates/event/event")),
  gift: dynamic(() => import("@/templates/gift/gift")),
  page: dynamic(() => import("@/templates/page/page")),
  protected: dynamic(() => import("@/templates/protected/protected"))
}
