import { default as NextLink } from "next/link"
import { useCopy } from "@/lib/contexts/appContext"
import { UserOnline, UserStroke } from "@/common/icons/member/user"
import { useAppContext } from "@/lib/contexts/appContext"
import { Caret } from "@/common/icons/member/caret"
import Close from "@/components/common/icons/close"
import { useMember } from "@/lib/hooks/useMember"
import Link from "@/components/common/menu/link"
import Button from "@/components/common/button/button"
import styles from "./menuItem.module.css"
import classNames from "classnames"

export default function MenuItem({
  context = "top",
  setActive,
  isActive,
  isMobile,
  handleOverlay
}) {
  const { general } = useCopy()
  const { meta } = useAppContext()
  const { hasMounted, isLoggedIn, menuItems, firstName, fullName, onLogout } =
    useMember()

  if (meta?.hideLogin && !isLoggedIn) {
    return null
  }

  switch (context) {
    case "header": // mobile header menu item
      return (
        <div
          className={classNames(styles.member, context, { on: isActive })}
          onClick={() => setActive("memberMenu", !isActive)}>
          <Content
            context={context}
            firstName={firstName}
            fullName={fullName}
            hasMounted={hasMounted}
            isLoggedIn={isLoggedIn}
            isActive={isActive}
            menu={menuItems}
            onLogout={onLogout}
          />
        </div>
      )

    case "top": // desktop top header menu item
      return (
        <li
          className={classNames(styles.member, context, { on: isLoggedIn })}
          onMouseEnter={() =>
            !isMobile && isLoggedIn && handleOverlay("header", true)
          }
          onMouseLeave={() =>
            !isMobile && isLoggedIn && handleOverlay("header", false)
          }>
          <Content
            context={context}
            firstName={firstName}
            fullName={fullName}
            hasMounted={hasMounted}
            isLoggedIn={isLoggedIn}
            menu={menuItems}
            onLogout={onLogout}
          />
        </li>
      )

    case "footer":
      return (
        <div className={classNames(styles.member, context)}>
          {!isLoggedIn && (
            <>
              <h3>Medlemssidor</h3>
              <p>{general?.loginDescription}</p>
              <Button
                theme="light-lime"
                size="small"
                tagName="a"
                href="/medlem/">
                Logga in
              </Button>
            </>
          )}
          {isLoggedIn && (
            <Content
              context={context}
              firstName={firstName}
              fullName={fullName}
              hasMounted={hasMounted}
              isLoggedIn={isLoggedIn}
              isActive={isActive}
              menu={menuItems}
              onLogout={onLogout}
            />
          )}
        </div>
      )
  }
}

const Content = ({
  context,
  firstName,
  fullName,
  hasMounted,
  isLoggedIn,
  isActive,
  menu,
  onLogout
}) => {
  const Icon = () => {
    if (["header"].includes(context) && isActive) {
      return <Close className="close" />
    }
    return <UserOnline className="person" />
  }

  if (hasMounted && !isLoggedIn) {
    return (
      <NextLink href="/medlem/" prefetch={false} legacyBehavior>
        <a>
          <UserStroke className="person" />
          {!["header"].includes(context) && <span>Logga in</span>}
        </a>
      </NextLink>
    )
  }

  if (firstName && hasMounted && isLoggedIn) {
    return (
      <>
        <div className="content">
          {!["footer"].includes(context) && <Icon />}
          {["footer"].includes(context) && <span>Medlemssidor</span>}
          {["top"].includes(context) && <span>{firstName}</span>}
          {["top"].includes(context) && <Caret className="caret" />}
        </div>
        <div
          className={classNames({
            "member-menu": !["footer"].includes(context)
          })}>
          <ul>
            {menu.map((item, index) => (
              <li key={item.url + index}>
                <Link item={item} location="member-menu" />
                {!["footer"].includes(context) && item.props?.new > 0 && (
                  <span className="badge">
                    {item.props.new} {item.props.new === 1 ? `ny` : `nya`}
                  </span>
                )}
              </li>
            ))}
          </ul>
          <span className="label">Inloggad som:</span>
          <span>{fullName}</span>
          <Button
            theme={["footer"].includes(context) ? "gray" : "light-lime"}
            onClick={(e) => onLogout(e)}>
            Logga ut
          </Button>
        </div>
      </>
    )
  }
}
